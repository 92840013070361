import React, { useContext } from 'react'
import styles from '../styles.module.scss'
import Textarea from '../../../components/Textarea'
import Input from '../../../components/Input'
import { QuestionContext } from '../../../utils/context'
import { AiOutlineCheck } from 'react-icons/ai'
import { IoMdClose } from "react-icons/io";
import Button from '../../../components/Button'


const ChoiceQuestion = () => {
  
  const {values, setFieldValue, handleChange} = useContext(QuestionContext)
 
  const handleInputChange = (index) => (e) => {
    const {name, value} = e.target
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers[index][name] = value
    setFieldValue('answers', answers)
  }

  const handleSetCorrect = (index) => (e) => {
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers.forEach((answer, i) => {answer.is_correct = index === i})
    setFieldValue('answers', answers)
  }

  const handleAddAnswer = () => {
    const newAnswer = {answer_text: `Вариант ${values.answers.length + 1}`, points: null, is_correct: false}
    const answers = [...values.answers, newAnswer]
    setFieldValue('answers', answers)
  }

  const handleDeleteAnswer = (index) => () => {
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers.splice(index, 1)
    setFieldValue('answers', answers)
  }

  const renderAnswer = (answer, index) => (
    <tr>
      <td>
        <h3>{index + 1}</h3>
      </td>
      <td>
        <Input
          name='answer_text'
          value={answer.answer_text}
          onChange={handleInputChange(index)}
          className={styles.input}
        />
      </td>
      <td onClick={handleSetCorrect(index)}>
        {!!answer.is_correct && <AiOutlineCheck color='green' size={26}/>}
      </td>
      <td>
        <IoMdClose color='red' size={26} title='Удалить' onClick={handleDeleteAnswer(index)}/>
      </td>
    </tr>
  )
   

  return (
    <>
      <h3>Введите текст вопроса</h3>
      <Textarea
        className={styles.textarea}
        name='question_text'
        value={values.question_text}
        onChange={handleChange}
      />

      <h3>Комментарий к вопросу</h3>
      <Textarea
        className={styles.textarea}
        style={{height: '6rem'}}
        name='comment'
        value={values.comment}
        onChange={handleChange}
      />

      <h3>Баллов за вопрос</h3>
      <Input
        name='points'
        value={values.points}
        onChange={handleChange}
        style={{width: '70px'}}
        type='number'
        min={0}
      />

      <h3>Введите варианты ответа</h3>
      <table>
        <thead>
          <tr>
            <td>№</td>
            <td>Текст ответа</td>
            {/* <td>Баллы</td> */}
            <td>Верный</td>
          </tr>
        </thead>
        <tbody className={styles.singleQBody}>
          {values.answers?.map(renderAnswer)}
        </tbody>
      </table>
      
      <Button text='Добавить вариант' onClick={handleAddAnswer}/>
      
      {/* <h3>Количество баллов</h3>
      <Input
        name='total_score'
        value={values.total_score}
        onChange={handleChange}
        style={{width: '70px'}}
        type='number'
      />
      
      <h3>Порядковый номер</h3>
      <Input
        name='number'
        value={values.number}
        onChange={handleChange}
        style={{width: '70px'}}
        type='number'
      /> */}
    </>
  )
}

export default ChoiceQuestion