import React, { useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import styles from './styles.module.scss'
import { MENU, version } from '../../assets/const'
import { FaPhoneAlt, FaEnvelope  } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth';
import classNames from 'classnames';
import { IoIosClose } from 'react-icons/io'
import { GiHamburgerMenu } from "react-icons/gi";


export default function Layout () {
  
  const dispatch = useDispatch()
  const {access_token} = useSelector(state => state.auth)

  const [burgerVisible, setBurgerVisible] = useState(false)

  const renderMenuItem = ({name, path, link}) => {
    const target = link ? '_blank' : '_self'
    const rel = link ? 'noopener noreferrer' : ''
    return (
      <li onClick={setMenuState(false)}>
        <NavLink target={target} rel={rel} to={path}>{name}</NavLink>
      </li>
    )
  }
  
  const logoutHandler = () => {
    dispatch(logOut())
  }
  
  const setMenuState = (value) => () => setBurgerVisible(value)

  return (
    <div className={styles.wrapper}>
      <section className={classNames(styles.burgerMenu, {[styles.visible]: burgerVisible})}>
        <IoIosClose onClick={setMenuState(false)}/>
        <ul>
          {MENU.map(renderMenuItem)}
          {!!access_token && <li onClick={logoutHandler}>выход</li>}
        </ul>
      </section>
      <address>
        <div>
          <div>
            <FaPhoneAlt/>
            +7 (495) 780-76-71
          </div>
          <div>
            <FaEnvelope/>
            info@nintegra.ru
          </div>
        </div>
      </address>
      <header>
        <div>
          <div>
            <NavLink to='/'>
              <img src='/Лого-и-текст-300х55.jpg' alt='logo' />
            </NavLink>
          </div>
          <ul>
            {MENU.map(renderMenuItem)}
            {!!access_token && <li onClick={logoutHandler}>выход</li>}
          </ul>
          <GiHamburgerMenu onClick={setMenuState(!burgerVisible)}/>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <span>{version}</span>
        <div>
          <div>
            <a href="http://www.nintegra.ru">ООО Нинтегра</a>
          </div>
          <div>Copyright © 2024 - ООО Нинтегра</div>
        </div>
      </footer>
    </div>
  )
}
