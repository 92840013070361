import React, { useContext } from 'react'
import styles from '../styles.module.scss'
import Textarea from '../../../components/Textarea'
import Input from '../../../components/Input'
import { QuestionContext } from '../../../utils/context'
import { AiOutlineCheck } from 'react-icons/ai'
import { IoMdClose } from "react-icons/io";
import Button from '../../../components/Button'
import classNames from 'classnames'



const MultipleQuestion = ({pointsNotValid}) => {
  
  const {values, errors, touched, handleBlur, submitCount, setFieldValue, handleChange} = useContext(QuestionContext)
 
  const handleInputChange = (index) => (e) => {
    const {name, value} = e.target
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers[index][name] = value
    setFieldValue('answers', answers)
  }

  const handleSetCorrect = (index) => (e) => {
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers[index].is_correct = !answers[index].is_correct
    setFieldValue('answers', answers)
  }

  const handleAddAnswer = () => {
    const newAnswer = {answer_text: `Вариант ${values.answers.length + 1}`, points: null, is_correct: false}
    const answers = [...values.answers, newAnswer]
    setFieldValue('answers', answers)
  }

  const handleDeleteAnswer = (index) => () => {
    const answers = JSON.parse(JSON.stringify(values.answers))
    answers.splice(index, 1)
    setFieldValue('answers', answers)
  }

  const error = fieldName => touched[fieldName] && errors[fieldName]

  const renderAnswer = (answer, index) => (
    <tr>
      <td>
        <h3>{index + 1}</h3>
      </td>
      <td>
        <Input
          name='answer_text'
          value={answer.answer_text}
          onChange={handleInputChange(index)}
          className={styles.input}
        />
      </td>
      <td>
        <Input
          className={classNames(styles.input, !pointsNotValid && styles.greenBorder)}
          name='points'
          value={answer.points}
          onChange={handleInputChange(index)}
          type='number'
          isValid={!pointsNotValid}
        />
      </td>
      <td onClick={handleSetCorrect(index)}>
        {!!answer.is_correct && <AiOutlineCheck color='green' size={26}/>}
      </td>
      <td>
        <IoMdClose color='red' size={26} title='Удалить' onClick={handleDeleteAnswer(index)}/>
      </td>
    </tr>
  )

  return (
    <>
      <h3>Введите текст вопроса</h3>
      <Textarea
        className={styles.textarea}
        name='question_text'
        value={values.question_text}
        onChange={handleChange}
      />
      
      <h3>Комментарий к вопросу</h3>
      <Textarea
        className={styles.textarea}
        style={{height: '6rem'}}
        name='comment'
        value={values.comment}
        onChange={handleChange}
      />

      <h3>Баллов за вопрос</h3>
      <Input
        name='points'
        value={values.points}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={error('points')}
        style={{width: '70px'}}
        type='number'
        min={0}
        isValid={!error('points')}
      />

      <h3>Введите варианты ответа</h3>
      <table>
        <thead>
          <tr>
            <td>№</td>
            <td>Текст ответа</td>
            <td>Баллы</td>
            <td>Верный</td>
          </tr>
        </thead>
        <tbody className={styles.multyQBody}>
          {values.answers?.map(renderAnswer)}
        </tbody>
      </table>
      
      <Button text='Добавить вариант' onClick={handleAddAnswer}/>
    </>
  )
}

export default MultipleQuestion